// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-authors-jsx": () => import("./../../../src/pages/authors.jsx" /* webpackChunkName: "component---src-pages-authors-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-templates-collection-author-js": () => import("./../../../src/templates/collection.author.js" /* webpackChunkName: "component---src-templates-collection-author-js" */),
  "component---src-templates-collection-category-js": () => import("./../../../src/templates/collection.category.js" /* webpackChunkName: "component---src-templates-collection-category-js" */),
  "component---src-templates-collection-tag-js": () => import("./../../../src/templates/collection.tag.js" /* webpackChunkName: "component---src-templates-collection-tag-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

